"use strict";
import { getChain, useIsSupportedChainId } from "constants/chains";
import { useIsDarkMode } from "theme/components/ThemeToggle";
import {
  ABSTRACT_LOGO,
  ANIME_TESTNET_LOGO,
  ARBITRUM_LOGO,
  AVALANCHE_LOGO,
  BASE_LOGO,
  BLAST_LOGO,
  BNB_LOGO,
  BOB_LOGO,
  CELO_LOGO,
  CYBER_LOGO,
  ETHEREUM_LOGO,
  INK_LOGO,
  OPTIMISM_LOGO,
  POLYGON_LOGO,
  REDSTONE_GARNET_LOGO,
  REDSTONE_LOGO,
  SHAPE_LOGO,
  ZERO_LOGO,
  ZKSYNC_LOGO,
  ZORA_LOGO
} from "ui/src/assets";
import { UniverseChainId } from "uniswap/src/types/chains";
export function getChainUI(chainId, darkMode) {
  switch (chainId) {
    case UniverseChainId.Mainnet:
    case UniverseChainId.Goerli:
    case UniverseChainId.Sepolia:
      return {
        symbol: ETHEREUM_LOGO,
        bgColor: "#6B8AFF33",
        textColor: "#6B8AFF"
      };
    case UniverseChainId.Polygon:
    case UniverseChainId.PolygonMumbai:
      return {
        symbol: POLYGON_LOGO,
        bgColor: "#9558FF33",
        textColor: "#9558FF"
      };
    case UniverseChainId.ArbitrumOne:
    case UniverseChainId.ArbitrumGoerli:
      return {
        symbol: ARBITRUM_LOGO,
        bgColor: "#00A3FF33",
        textColor: "#00A3FF"
      };
    case UniverseChainId.Optimism:
    case UniverseChainId.OptimismGoerli:
      return {
        symbol: OPTIMISM_LOGO,
        bgColor: "#FF042033",
        textColor: "#FF0420"
      };
    case UniverseChainId.Celo:
    case UniverseChainId.CeloAlfajores:
      return darkMode ? {
        symbol: CELO_LOGO,
        bgColor: "#FCFF5233",
        textColor: "#FCFF52"
      } : {
        symbol: CELO_LOGO,
        bgColor: "#FCFF5299",
        textColor: "#655947"
      };
    case UniverseChainId.Avalanche:
      return {
        symbol: AVALANCHE_LOGO,
        bgColor: "#E8414233",
        textColor: "#E84142"
      };
    case UniverseChainId.Bnb:
      return {
        symbol: BNB_LOGO,
        bgColor: "#EAB20033",
        textColor: "#EAB200"
      };
    case UniverseChainId.Base:
      return {
        symbol: BASE_LOGO,
        bgColor: "#0052FF33",
        textColor: "#0052FF"
      };
    case UniverseChainId.Blast:
      return darkMode ? {
        symbol: BLAST_LOGO,
        bgColor: "rgba(252, 252, 3, 0.12)",
        textColor: "rgba(252, 252, 3, 1) "
      } : {
        symbol: BLAST_LOGO,
        bgColor: "rgba(252, 252, 3, 0.16)",
        textColor: "rgba(17, 20, 12, 1)"
      };
    case UniverseChainId.Zora:
      return darkMode ? {
        symbol: ZORA_LOGO,
        bgColor: "rgba(255, 255, 255, 0.12)",
        textColor: "#FFFFFF"
      } : {
        symbol: ZORA_LOGO,
        bgColor: "rgba(0, 0, 0, 0.12)",
        textColor: "#000000"
      };
    case UniverseChainId.Zksync:
      return darkMode ? {
        symbol: ZKSYNC_LOGO,
        bgColor: "rgba(97, 137, 255, 0.12)",
        textColor: "#6189FF"
      } : {
        symbol: ZKSYNC_LOGO,
        bgColor: "rgba(54, 103, 246, 0.12)",
        textColor: "#3667F6"
      };
    case UniverseChainId.AbstractTestnet:
    case UniverseChainId.AbstractMainnet:
      return darkMode ? {
        symbol: ABSTRACT_LOGO,
        bgColor: "rgba(187, 247, 208, 0.12)",
        textColor: "#BBF7D0"
      } : {
        symbol: ABSTRACT_LOGO,
        bgColor: "rgba(0, 222, 115, 0.12)",
        textColor: "#00DE73"
      };
    case UniverseChainId.Zero:
      return darkMode ? {
        symbol: ZERO_LOGO,
        bgColor: "rgba(97, 137, 255, 0.12)",
        textColor: "#6189FF"
      } : {
        symbol: ZERO_LOGO,
        bgColor: "rgba(54, 103, 246, 0.12)",
        textColor: "#3667F6"
      };
    case UniverseChainId.BOB:
      return darkMode ? {
        symbol: BOB_LOGO,
        bgColor: "rgba(97, 137, 255, 0.12)",
        textColor: "#6189FF"
      } : {
        symbol: BOB_LOGO,
        bgColor: "rgba(54, 103, 246, 0.12)",
        textColor: "#3667F6"
      };
    case UniverseChainId.CYBER:
      return darkMode ? {
        symbol: CYBER_LOGO,
        bgColor: "rgba(97, 137, 255, 0.12)",
        textColor: "#6189FF"
      } : {
        symbol: CYBER_LOGO,
        bgColor: "rgba(54, 103, 246, 0.12)",
        textColor: "#3667F6"
      };
    case UniverseChainId.SHAPE:
      return darkMode ? {
        symbol: SHAPE_LOGO,
        bgColor: "rgba(97, 137, 255, 0.12)",
        textColor: "#6189FF"
      } : {
        symbol: SHAPE_LOGO,
        bgColor: "rgba(54, 103, 246, 0.12)",
        textColor: "#3667F6"
      };
    case UniverseChainId.INK:
      return darkMode ? {
        symbol: INK_LOGO,
        bgColor: "rgba(97, 137, 255, 0.12)",
        textColor: "#6189FF"
      } : {
        symbol: INK_LOGO,
        bgColor: "rgba(54, 103, 246, 0.12)",
        textColor: "#3667F6"
      };
    case UniverseChainId.REDSTONE:
      return {
        symbol: REDSTONE_LOGO,
        bgColor: "#FF042033",
        textColor: "#FF0420"
      };
    case UniverseChainId.REDSTONE_GARNET:
      return {
        symbol: REDSTONE_GARNET_LOGO,
        bgColor: "#FF042033",
        textColor: "#FF0420"
      };
    case UniverseChainId.AnimeTestnet:
      return darkMode ? {
        symbol: ANIME_TESTNET_LOGO,
        bgColor: "rgba(97, 137, 255, 0.12)",
        textColor: "#6189FF"
      } : {
        symbol: ANIME_TESTNET_LOGO,
        bgColor: "rgba(54, 103, 246, 0.12)",
        textColor: "#3667F6"
      };
    default:
      return void 0;
  }
}
const getDefaultBorderRadius = (size) => size / 2 - 4;
export function ChainLogo({
  chainId,
  style,
  size = 12,
  borderRadius = getDefaultBorderRadius(size),
  testId,
  fillContainer = false
}) {
  const darkMode = useIsDarkMode();
  const isSupportedChain = useIsSupportedChainId(chainId);
  if (!isSupportedChain) {
    return null;
  }
  const { label } = getChain({ chainId });
  const { symbol } = getChainUI(chainId, darkMode);
  const iconSize = fillContainer ? "100%" : size + "px";
  return symbol ? <img
    aria-labelledby="titleID"
    data-testid={testId}
    width={iconSize}
    height={iconSize}
    src={symbol}
    style={{ ...style, borderRadius: borderRadius + "px" }}
    alt={`${label} logo`}
  /> : null;
}
