"use strict";
import { CurrencyListRow, CurrencyListSectionTitle } from "components/SearchModal/CurrencyList";
import { useDefaultActiveTokens } from "hooks/Tokens";
import { useTokenListToken } from "hooks/TokensLegacy";
import { useTokenBalances } from "hooks/useTokenBalances";
import { getTokenFilter } from "lib/hooks/useTokenList/filtering";
import { getSortedPortfolioTokens } from "lib/hooks/useTokenList/sorting";
import { useMemo } from "react";
import { useUserAddedTokens } from "state/user/userAddedTokens";
import { UserAddedToken } from "types/tokens";
import { useSwapAndLimitContext } from "state/swap/useSwapContext";
const currencyListRowMapper = (currency) => new CurrencyListRow(currency);
const searchResultsCurrencyListMapper = (currency) => new CurrencyListRow(currency, { showAddress: true });
function isEmpty(query) {
  return !query || query.length === 0;
}
export function useCurrencySearchResultsLegacy({
  searchQuery,
  filters,
  selectedCurrency,
  otherSelectedCurrency
}) {
  const { chainId } = useSwapAndLimitContext();
  const gqlTokenListsEnabled = false;
  const { balanceMap, balanceList, loading: balancesLoading } = useTokenBalances();
  const searchToken = useTokenListToken(searchQuery);
  const defaultAndUserAddedTokens = useDefaultActiveTokens(chainId);
  const userAddedTokens = useUserAddedTokens();
  const gqlSearchResultsEmpty = true;
  const gqlPopularTokensEmpty = true;
  const { sortedCombinedTokens, portfolioTokens, sortedTokensWithoutPortfolio } = useMemo(() => {
    const fullBaseList = (() => {
      if (!gqlTokenListsEnabled || !isEmpty(searchQuery) && gqlSearchResultsEmpty || isEmpty(searchQuery) && gqlPopularTokensEmpty) {
        return Object.values(defaultAndUserAddedTokens);
      } else if (!isEmpty(searchQuery)) {
        return [...userAddedTokens.filter(getTokenFilter(searchQuery))];
      } else {
        return [...userAddedTokens];
      }
    })();
    if (gqlTokenListsEnabled && !isEmpty(searchQuery) && !gqlSearchResultsEmpty) {
      return {
        sortedCombinedTokens: fullBaseList,
        portfolioTokens: [],
        sortedTokensWithoutPortfolio: fullBaseList
      };
    }
    const filteredListTokens = fullBaseList.filter((token) => {
      return token.symbol !== "ETH" && token.name !== "" && token.symbol !== "";
    });
    const portfolioTokens2 = getSortedPortfolioTokens(balanceList, balanceMap, chainId, {
      hideSmallBalances: false,
      hideSpam: true
    });
    const mergedTokens = [...portfolioTokens2 ?? [], ...filteredListTokens];
    const currencyFilter = (currency) => {
      if (filters?.onlyShowCurrenciesWithBalance) {
        if (currency.isNative) {
          return balanceMap[currency.symbol ?? "ETH"]?.usdValue > 0;
        }
        return balanceMap[currency.address?.toLowerCase()]?.usdValue > 0;
      }
      if (currency.isNative && filters?.disableNonToken) {
        return false;
      }
      if (isEmpty(searchQuery) && currency instanceof UserAddedToken) {
        if (selectedCurrency?.equals(currency) || otherSelectedCurrency?.equals(currency)) {
          return true;
        }
        return balanceMap[currency.address.toLowerCase()]?.usdValue > 0;
      }
      return true;
    };
    const sortedCombinedTokens2 = !isEmpty(searchQuery) && (!gqlTokenListsEnabled || gqlSearchResultsEmpty) ? mergedTokens.filter(getTokenFilter(searchQuery)) : mergedTokens;
    return {
      sortedCombinedTokens: sortedCombinedTokens2.filter(currencyFilter),
      sortedTokensWithoutPortfolio: filteredListTokens.filter(currencyFilter),
      portfolioTokens: portfolioTokens2.filter(currencyFilter)
    };
  }, [
    gqlTokenListsEnabled,
    searchQuery,
    gqlSearchResultsEmpty,
    balanceList,
    balanceMap,
    chainId,
    gqlPopularTokensEmpty,
    defaultAndUserAddedTokens,
    userAddedTokens,
    filters?.onlyShowCurrenciesWithBalance,
    filters?.disableNonToken,
    selectedCurrency,
    otherSelectedCurrency
  ]);
  const finalCurrencyList = useMemo(() => {
    if (!isEmpty(searchQuery) || portfolioTokens.length === 0) {
      return [
        new CurrencyListSectionTitle(searchQuery ? `Search results` : `Popular tokens`),
        ...sortedCombinedTokens.map(searchQuery ? searchResultsCurrencyListMapper : currencyListRowMapper)
      ];
    } else if (sortedTokensWithoutPortfolio.length === 0) {
      return [new CurrencyListSectionTitle(`Your tokens`), ...portfolioTokens.map(currencyListRowMapper)];
    } else {
      return [
        new CurrencyListSectionTitle(`Your tokens`),
        ...portfolioTokens.map(currencyListRowMapper),
        new CurrencyListSectionTitle(`Popular tokens`),
        ...sortedTokensWithoutPortfolio.map(currencyListRowMapper)
      ];
    }
  }, [searchQuery, portfolioTokens, sortedTokensWithoutPortfolio, sortedCombinedTokens]);
  return {
    loading: balancesLoading,
    searchCurrency: searchToken,
    allCurrencyRows: finalCurrencyList
  };
}
