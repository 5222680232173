"use strict";
import { Token } from "@uniswap/sdk-core";
import tokenLogoLookup from "constants/tokenLogoLookup";
import { getNativeLogoURI } from "lib/hooks/useCurrencyLogoURIs";
import uriToHttp from "lib/utils/uriToHttp";
import { useCallback, useMemo, useReducer } from "react";
const BAD_SRCS = {};
function parseLogoSources(uris) {
  const urls = [];
  uris.forEach((uri) => urls.push(...uriToHttp(uri)));
  return urls;
}
function prioritizeLogoSources(uris) {
  const parsedUris = uris.map((uri) => uriToHttp(uri)).flat(1);
  const preferredUris = [];
  let coingeckoUrl = void 0;
  parsedUris.forEach((uri) => {
    if (uri.startsWith("https://assets.coingecko")) {
      if (!coingeckoUrl) {
        coingeckoUrl = uri.replace(/\/small\/|\/thumb\//g, "/large/");
      }
    } else {
      preferredUris.push(uri);
    }
  });
  return coingeckoUrl ? [...preferredUris, coingeckoUrl] : preferredUris;
}
function getInitialUrl(address, chainId, isNative, backupImg) {
  if (chainId && isNative) {
    return getNativeLogoURI(chainId);
  } else {
    return backupImg;
  }
}
export default function useAssetLogoSource(currency) {
  const address = currency instanceof Token ? currency?.address : void 0;
  const chainId = currency?.chainId;
  const [srcIndex, incrementSrcIndex] = useReducer((n) => n + 1, 0);
  const current = useMemo(() => {
    const initialUrl = getInitialUrl(address, chainId, false);
    if (initialUrl && !BAD_SRCS[initialUrl]) {
      return initialUrl;
    }
    const uris = tokenLogoLookup.getIcons(address, chainId) ?? [];
    const fallbackSrcs = prioritizeLogoSources(parseLogoSources(uris));
    return fallbackSrcs.find((src) => !BAD_SRCS[src]);
  }, [address, chainId, srcIndex]);
  const nextSrc = useCallback(() => {
    if (current) {
      BAD_SRCS[current] = true;
    }
    incrementSrcIndex();
  }, [current]);
  return [current, nextSrc];
}
