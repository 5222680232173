"use strict";
const forkConfig = {
  signUpOptionEnabled: false,
  uniSpecificFeaturesEnabled: false,
  exploreEnabled: false,
  multichainFlagEnabled: false,
  analyticsEnabled: false,
  blockedPaths: ["/buy"],
  approvedTokens: false,
  landingPageExternalData: false,
  multipleRouteOptionsEnabled: true,
  apiRoutingEnabled: true,
  inDevelopment: false,
  documentationAvailable: false
};
export default forkConfig;
