"use strict";
import store from "state";
import { DEFAULT_LIST_OF_LISTS } from "constants/lists";
class TokenLogoLookupTable {
  dict = {};
  initialized = false;
  initialize() {
    const dict = {};
    DEFAULT_LIST_OF_LISTS.forEach((list) => {
      const listData = store.getState().lists.byUrl[list];
      if (!listData) {
        return;
      }
      listData.current?.tokens.forEach((token) => {
        if (token.logoURI) {
          const lowercaseAddress = token.address.toLowerCase();
          const currentEntry = dict[lowercaseAddress + ":" + token.chainId];
          if (currentEntry) {
            currentEntry.push(token.logoURI);
          } else {
            dict[lowercaseAddress + ":" + token.chainId] = [token.logoURI];
          }
        }
      });
    });
    this.dict = dict;
    this.initialized = true;
  }
  getIcons(address, chainId = 1) {
    if (!address) {
      return void 0;
    }
    if (!this.initialized) {
      this.initialize();
    }
    return this.dict[address.toLowerCase() + ":" + chainId];
  }
}
export default new TokenLogoLookupTable();
